<template>
	<el-dialog title="查看办理情况征询表" :visible.sync="ckyjbshow" width="700px">
		<div>
			<div class="title">
				政协宁夏委员会提交办理情况征询表
			</div>
			<div class="table">
				<div class="line">
					<div class="name">
						姓名
					</div>
					<div class="value">
						{{detail.user_name}}
					</div>
					<div class="name" style="width: 25%;">
						通讯地址或工作单位
					</div>
					<div class="value" style="width: 40%;">
						{{detail.organizational_list[0]?detail.organizational_list[0].address:''}}
					</div>
				</div>
				<div class="line">
					<div style="height: 80px;width: 35%;display: flex;">
						<div class="name" style="height: 100%;border-top: none;width: 43%;">
							提案<br>编号
						</div>
						<div class="value" style="height: 100%;border-top: none;width: 57%;">
							{{detail.code}}
						</div>
					</div>
					<div style="height: 80px;width: 65%;">
						<div style="display: flex;align-items: center;">
							<div class="name" style="width: 38.5%;border-top: none;">
								提案标题
							</div>
							<div class="value" style="border-top: none;border-right: 1px solid #333;width: 61.5%;">
								{{detail.name}}
							</div>
						</div>
						<div style="display: flex;align-items: center;">
							<div class="name" style="width: 38.5%;border-top: none;">
								承办单位
							</div>
							<div class="value" style="border-top: none;border-right: 1px solid #333;width: 61.5%;">
								{{detail.organizational_list[0]?detail.organizational_list[0].organizational_name:''}}
							</div>
						</div>
					</div>
				</div>
				<div class="line">
					<div style="display: flex;width: 60%;">
						<div class="name" style="border-top: none;width: 45%;">
							收到答复日期
						</div>
						<div class="value" style="border-top: none;width: 55%;">
							{{detail.organizational_list[0]?detail.organizational_list[0].dftime.slice(0, -5):''}}
						</div>
					</div>
					<div style="display: flex;width: 40%;">
						<div class="name" style="border-top: none;width: 45%;">
							收到答复日期
						</div>
						<div class="value" style="border-top: none;width: 55%;border-right: 1px solid #333;">
							{{detail.organizational_list[0]?detail.organizational_list[0].pjtime.slice(0, -5):''}}
						</div>
					</div>
				</div>
				<div class="line">
					<div style="display: flex;width: 10%;">
						<div class="name" style="border-top: none;width: 100%;height: 250px;border-right: 1px solid #333;">
							委员对办理的具体意见和建议
						</div>
					</div>
					<div style="width: 90%;height: 250px;">
						<div style="display: flex;width: 100%;">
							<div class="name" style="width: 15%;border-top: none;border-left: none;">
								满意
							</div>
							<div class="value" style="width: 17%;border-top: none;border-right: none;">
								<div v-show="detail.organizational_list[0].sfmy==3" style="width: 10px;height: 20px;border-right: 2px solid #333;border-bottom: 2px solid #333;transform: rotate(45deg);">
									
								</div>
							</div>
							<div class="name" style="width: 19%;border-top: none;border-right: none;">
								基本满意
							</div>
							<div class="value" style="width: 17%;border-top: none;border-right: none;">
								<div v-show="detail.organizational_list[0].sfmy==2" style="width: 10px;height: 20px;border-right: 2px solid #333;border-bottom: 2px solid #333;transform: rotate(45deg);">
									
								</div>
							</div>
							<div class="name" style="width: 15%;border-top: none;border-right: none;">
								不满意
							</div>
							<div class="value" style="width: 17%;border-top: none;border-right: 1px solid #333;">
								<div v-show="detail.organizational_list[0].sfmy==1" style="width: 10px;height: 20px;border-right: 2px solid #333;border-bottom: 2px solid #333;transform: rotate(45deg);">
									
								</div>
							</div>
						</div>
						<div class="value" style="width:100%;border-top: none;border-left:none;height: 210px;border-right: 1px solid #333;">	
							{{detail.organizational_list[0].qhzxyjb}}
						</div>
					</div>
				</div>
			</div>
		</div>
		<span slot="footer" class="dialog-footer">
			<el-button @click="ckyjbshow=false">关闭</el-button>
		</span>
	</el-dialog>
</template>

<script>
	export default{
		data() {
			return{
				ckyjbshow:false,
				detail:'',
			}
		}
	}
</script>

<style lang="scss" scoped>
	.table{
		width: 100%;
		margin-top: 20px;
		.line{
			display: flex;
			align-items: center;
			.name{
				width: 15%;
				border: 1px solid #333333;
				font-size: 11px;
				height: 40px;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 0 3px;
				border-right: none;
			}
			.value{
				width: 20%;
				border: 1px solid #333333;
				font-size: 11px;
				height: 40px;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 0 3px;
			}
			.value:nth-child(2){
				border-right: none;
			}
		}
	}
	.cover {
		width: 100vw;
		height: 100vh;
		position: fixed;
		left: 0;
		top: 0;
		background-color: #fff;
		z-index: 9999;
		padding: 20px;
	}
	.title{
		color: #333333;
		font-size: 23px;
		font-weight: bold;
		text-align: center;
	}
</style>